import request from '../../request'
export default {
  state: {
    name: 'DataDisplay',
    totalCount: 1,
    totalTotalCount: 1,
    streetInfo: {},
    moreHealthy: false,
    totalInfo: {},
    drCount: 1
  },
  reducers: {
    updateState(state, payload) {
      return { ...state, ...payload }
    }
  },
  effects: {
    async getStreetStat() {
      const url = '/patient/api/dataMap/streetStatInfo/2'
      this.getTotalStat()
      const res = await request.get(url)

      if (res.status === 0) {
        // const streetInfo = {
        //   drFourCount: '2',
        //   drOneCount: '0',
        //   drThreeCount: '0',
        //   drTwoCount: '0',
        //   healthyCount: '10',
        //   incidenceRate: 0.75,
        //   screeningCount: '40',
        //   streetId: '2',
        //   unhealthyCount: '30'
        // }
        const streetInfo = res.data
        const unheal = parseInt(streetInfo.unhealthyCount)
        const heal = parseInt(streetInfo.healthyCount)
        const drCount = ~~streetInfo.drOneCount + ~~streetInfo.drTwoCount + ~~streetInfo.drThreeCount + ~~streetInfo.drFourCount
        let moreHealthy = false
        if (heal > unheal) {
          moreHealthy = true
        }
        // console.log(drCount)
        this.updateState({
          streetInfo,
          moreHealthy,
          drCount,
          totalCount: streetInfo.screeningCount
        })

      }
    },
    async getTotalStat() {
      const url = '/patient/api/dataMap/allStatInfo/05730606'
      const res = await request.get(url)

      if (res.status === 0) {
        const dataInfo = res.data

        this.updateState({
          totalInfo: dataInfo,
          totalTotalCount: dataInfo.screeningCount
        })
      }
    }
   }
}