import request from '../../request'
import { modelMsg } from '../../components/modelMethods'
import { push } from 'react-router-redux'
const md5 = require('js-md5')

export default {
  state: {
    name: 'EcgUserList',
    params: {
      page: 1,
      pageSize: 10,
      organizationId: '',
      title: '',
      realNameOrAccount: ''
    },
    userInfo: {},
    ecgUserList: [],
    hospitalList: [],
    titleList: [],
    persGroup: [],

    persGroupSuper: [
      { configKey: "机构管理员", configValue: "3" },
      { configKey: "报告医生", configValue: "2" },
    ],
    persGroupSmall: [
      // { configKey: "机构管理员", configValue: "3" },
      { configKey: "报告医生", configValue: "2" },
    ],
    hospitalCode: '',
    totalRows: 1,
    expirationTime: 0,
    dynamicCode: '',
    serialNumber: ''

  },
  reducers: {
    updateState(state, payload) {
      return { ...state, ...payload }
    }
  },
  effects: (dispatch) => ({
    // async outPut() {
    //   const url = '/patient/api/screening/exportList'
    //   const params = payload
    //   cons
    // },
    async getArea(payload) {
      dispatch.UserControll.getArea(payload)
      this.getTitleList()
      this.getPersGroupList()
    },
    async getHospitals(payload) {
      dispatch.UserControll.getHospitals(payload)
    },


    async resetPwd(payload) {
      const url = `/api/user/resetPassword/${payload.userId}`
      const params = {
        newPassword: md5(payload.newPassword  + 'truthai^&%$')
      }

      const res = await request.post(url, params)
      if (res.status === 0) {
        modelMsg(res.status, '密码修改成功！')
      } else {
        modelMsg(res.status, res.msg)
      }
    },
    async addEcgUser(payload) {
      const url = '/api/user/addUser'
      const params = payload
      const cb = params.cb
      delete params.cb
      const res = await request.post(url, params)

      const permsGroupId = payload.permsGroupId
      const realName = payload.realName
      if (res.status === 0) {
        if (permsGroupId == '3') {
          cb({
            msg: `管理员替换/该机构管理员已经替换为${realName}！`,
            title: '新增成功',
            titleType: 'default',
            cancelFunc: function() {
              setTimeout(() => {
                dispatch(push('/ecgUserList'))
              });
              
            }
          })
        } else {
          modelMsg(0, '新增用户成功！')
          console.log(444444)

          setTimeout(() => {
            dispatch(push('/ecgUserList'))
          });
        }
      } else {
        modelMsg(2, res.msg)
      }
    },
    async editEcgUser(payload) {
      const url = `/api/user/modify/${payload.id}`
      const params = payload
      const cb = params.cb
      delete params.cb
      const res = await request.post(url, params)
      const permsGroupId = payload.permsGroupId
      const realName = payload.realName

      if (res.status === 0) {
        // if (permsGroupId == '3') {
        //   cb({
        //     msg: `管理员替换/该机构管理员已经替换为${realName}}！`,
        //     title: '新增成功',
        //     titleType: 'default',
        //     cancelFunc: function() {
        //       dispatch(push('/ecgUserList'))
        //     }
        //   })
        // } else {
        //   modelMsg(0, '新增用户成功！')
        // }
        modelMsg(0, '保存成功！')
        setTimeout(() => {
          dispatch(push('/ecgUserList'))
        });
      } else {
        modelMsg(res.status, res.msg)
      }
    },

    async getEcgUserDetail(payload) {
      const url = `/api/user/detail/${payload.id}`
      const params = payload
      const res = await request.get(url, {params})
      console.log(res)
      if (res.status === 0) {
        this.updateState({
          userInfo: res.data
        })
      } else {
        modelMsg(res.status, res.msg)
      }
    },

    async modifyCertificationState(payload) {
      const url = `/api/user/modifyCertificationState/${payload.id}`
      const params = payload
      const res = await request.post(url, params)

      if (res.status === 0) {
        modelMsg(0, res.msg)
        this.getEcgUserList()
      } else {
        modelMsg(0, res.msg)
      }
    },

    async modifyState(payload) {
      const url = `/api/user/modifyState/${payload.id}`
      const params = payload
      const res = await request.post(url, params)

      if (res.status === 0) {
        modelMsg(0, res.msg)
        this.getEcgUserList()
      } else {
        modelMsg(0, res.msg)
      }
    },

    async getEcgUserList(payload = {}, rootState) {
      const url = '/api/user/page'
      const params = {...rootState.EcgUserList.params, ...payload}
      const res = await request.get(url, { params })

      if (res.status === 0) {
        this.updateState({
          ecgUserList: res.data.pageData,
          totalRows: res.data.totalRows,
          params
        })
        if (rootState.EcgUserList.params.reset && !payload.first) {
          rootState.EcgUserList.params.reset()
        }
      }
    },
    async getTitleList() {
      const url = '/api/config/getTitles'
      const res = await request.get(url)

      if (res.status === 0) {
        this.updateState({
          titleList: res.data
        })
      }

    },
    async getPersGroupList() {
      const url = '/api/config/getPermsGroups'
      const res = await request.get(url)

      if (res.status === 0) {
        this.updateState({
          persGroup: res.data
        })
      }

    },
  })
}