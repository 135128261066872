import React, { Component } from 'react'
import MyIcon from '../../components/myIcon'
import { Button } from 'antd'
import './commonModal.styl'

class CommonModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
      msg: '',
      cancelMsg: '',
      okMsg: '',
      cancelFunc: null,
      okFunc: null,
      okFuncParams: ''
    }
  }
  resetOption() {
    this.setState({
      show: false,
      msg: '',
      cancelMsg: '',
      okMsg: '',
      cancelFunc: null,
      okFunc: null,
      okFuncParams: ''
    })
  }
  ok = () => {
    this.resetOption()
    if (this.state.okFunc) {
      this.state.okFunc(this.state.okFuncParams)
    }
  }
  show = (params) => {
    const { title, modalType, titleType, msg, okFuncParams, cancelMsg, okMsg, okFunc, cancelFunc } = params

    this.setState({
      title,
      msg,
      titleType,
      show: true,
      cancelMsg,
      modalType,
      okMsg,
      okFuncParams,
      cancelFunc,
      okFunc
    })
  }
  close = () => {
    this.resetOption()

    if (this.state.cancelFunc) {
      this.state.cancelFunc()
    }
  }
  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this)

    }
    this.setState({
      msg: <span style={{color: 'green'}}>  123123</span>
    })
  }
  render () {
    const { show, titleType, title, msg, modalType, cancelMsg, okMsg } = this.state
    return (
      show ?
      <div className='commonModal-content'>
        <div className='alert-modal'>
          <p className='alert-title'>
            {/* {
              !titleType || titleType === 'default' ?
              <MyIcon style={{fontSize: 20, marginRight: 10}} type='icontishi' /> : null
            } */}
            
            {
              titleType === 'success' ?
              <MyIcon style={{fontSize: 20, marginRight: 10}} type='iconchenggong' /> : null
            }
            {
              titleType === 'warn' ?
              <MyIcon style={{fontSize: 20, marginRight: 10}} type='icontishi' /> : null
            }
            {
              titleType === 'danger' ?
              <MyIcon style={{fontSize: 20, marginRight: 10}} type='iconjinggao' /> : null
            }
            {title || '提示信息'}
          </p>
          <div className='msg'>
            {/* <MyIcon style={{fontSize: 20, marginRight: 10}} type='icon-jinghao' /> */}
            { msg || '确认操作？'}
          </div>
          {
            modalType == 'confirm' ?
            <div className='btn-group'>
              <Button
                className='my-btn my-cancel-btn'
                onClick={this.close}
                type='primary'
                ghost> {cancelMsg || '取消'} </Button>
              <Button
                className='my-btn my-sure-btn'
                onClick={this.ok}
                type='primary'> {okMsg || '确认'} </Button>
            </div> : null
          }
          {
            !modalType || modalType == 'default' ?
            <div className='btn-group'>
              <Button
                className='my-btn'
                onClick={this.close}
                type='primary'
                ghost> 知道了
              </Button>
            </div> : null
          }
        </div>
      </div> : null
    )
  }
}

export default CommonModal