import { init } from '@rematch/core'
import models from './models'
import { history } from './routers'
import { routerReducer, routerMiddleware } from 'react-router-redux'

const store = init({
  models,
  redux: {
    reducers: {
      routing: routerReducer
    },
    middlewares: [routerMiddleware(history)]
  }
})

export const { dispatch } = store

if (module.hot) {
  // module.hot.accept('./models', () => {
  //   store.replaceReducer(dispatch)
  // })
  module.hot.accept('./models', () => {
    const nextModels = require("./models").default
    Object.keys(nextModels).forEach(modelKey => {
      store.model({
        name: modelKey,
        ...models[modelKey]
      })
    })

  })
}

export default store