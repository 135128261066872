import React, { Component, lazy, Suspense  } from 'react'
import { ConnectedRouter } from 'react-router-redux'
import { Switch, Route, Redirect } from 'react-router-dom'
// import createHistory from 'history/createBrowserHistory'

import { Pc, Mobile } from './mode'
const createHistory = require("history").createBrowserHistory

const Login = lazy(() => import('./views/Login/login'))
const TestPage = lazy(() => import('./views/TestPage/testPage'))
const Index = lazy(() => import('./views/Index/index'))

const Permission = lazy(() => import('./views/Permission/permission'))
const RoleControll = lazy(() => import('./views/RoleControll/roleControll'))
const UserControll = lazy(() => import('./views/UserControll/userControll'))
const HospitalControll = lazy(() => import('./views/HospitalControll/hospitalControl'))

const Personal = lazy(() => import('./views/Personal/personal'))


//error-page
const ErrorPage404 = lazy(() => import('./views/ErrorPage/404'))

const DataDisplay  = lazy(() => import('./views/DataDisplay/dataDisplay'))

const EcgUserList  = lazy(() => import('./views/EcgUserList/ecgUserList'))
const AddEcgUser  = lazy(() => import('./views/EcgUserList/addEcgUser'))

const EcgHospitalList  = lazy(() => import('./views/EcgHospitalList/ecgHospitalList'))
const AddEcgHospital  = lazy(() => import('./views/EcgHospitalList/addEcgHospital'))

const AddRegister  = lazy(() => import('./views/RegisterList/addRegister'))
const RegisterList  = lazy(() => import('./views/RegisterList/registerList'))
const RegisterDetail  = lazy(() => import('./views/RegisterList/registerDetail'))

const ActiveSerial  = lazy(() => import('./views/ActiveSerial/activeSerial'))


const EcgView = lazy(() => import('./views/EcgView/ecgView'))






export const history = createHistory()
// render={()=> <PatientList />} || component={() => <PatientList />} , react-router-dom 4.4版本也能解决这个问题？

// 懒加载的组件 Suspense

class Routes extends Component {
  render() {
    return (
      <ConnectedRouter history={history}>
        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            <Route path='/login' component={()=> <Login />} />
            <Route path='/activeSerial/:id' component={()=> <ActiveSerial />} />

            <Route path='/dataDisplay' component={()=> <DataDisplay />} />
            <Route path='/registerDetail/:id' component={()=> <RegisterDetail />} />

            <Route path='/h5' component={() =>
              <Mobile>
                <Suspense fallback={<div>Loading...</div>}>
                  <Switch>
                    {/* <Route path='/h5/h5login' component={()=> <H5Login />} /> */}
                  </Switch>
                </Suspense>
              </Mobile>} />

            <Route path='/' component={() =>
              <Pc>
                <Suspense fallback={<div>Loading...</div>}>
                  <Switch>
                    <Route path='/' exact component={()=> <Index />} />

                    <Route path='/permission' component={()=> <Permission />} />
                    <Route path='/roleControll' component={()=> <RoleControll />} />
                    <Route path='/userControll' component={()=> <UserControll />} />
                    <Route path='/hospitalControll' component={()=> <HospitalControll />} />

                    <Route path='/testPage' component={()=> <TestPage />} />
                    <Route path='/personal/:id' component={()=> <Personal />} />

                    <Route path='/ecgUserList' component={()=> <EcgUserList />} />
                    <Route path='/addEcgUser/:id?' component={()=> <AddEcgUser />} />

                    <Route path='/ecgHospitalList' component={()=> <EcgHospitalList />} />
                    <Route path='/addEcgHospital/:id?' component={()=> <AddEcgHospital />} />


                    <Route path='/addRegister' component={()=> <AddRegister />} />
                    <Route path='/registerList' component={()=> <RegisterList />} />



                    {/* <Route path="/404" component={()=> <ErrorPage404 />} />
                    <Redirect from='*' to='/404' /> */}
                  </Switch>
                </Suspense>
              </Pc>} />
          </Switch>

        </Suspense>
      </ConnectedRouter>
    )
  }
}

export default Routes;
