import React, { Component } from 'react'

import { Layout } from 'antd'
import SiderBar from './views/Layouts/siderBar'
import Bread from './views/Layouts/Bread'
import Header from './views/Layouts/header'



export class Pc extends Component {

  render() {
    const { Content, Footer } = Layout;
    let style
    let styleContainer

    if (window.isPad) {
      style = { marginLeft: 80, overflowY: 'hidden' }
      styleContainer= { minHeight: '100vh', position: 'relative'}
    } else {
      style = { marginLeft: 180, overflowY: 'hidden', minWidth: 1066 }
      styleContainer= { minHeight: '100vh', position: 'relative', minWIdth: 1350}

    }
    return (
      <Layout style={styleContainer}>
        <SiderBar/>
        <Layout style={style}>
          <Header />
          <Bread />
          <Content style={{ margin: '0 16px', borderRadius: 4 }}>
            {this.props.children}
          </Content>
          {/* <Footer style={{ textAlign: 'center' }}>
            Truth Ai System ©2018 Created by Truth
            <p><a target='_blank' href="http://www.beian.miit.gov.cn">ICP证：浙ICP备18027717号</a></p>
          </Footer> */}
        </Layout>
      </Layout>
    );
  }
}


export class Mobile extends Component {
  componentDidMount() {
    this.setFontSize()
    // const that = this
    // window.onresize = function() {
    //   that.setFontSize()
    // }
  }
  setFontSize = () => {
    let oHtml = document.documentElement
    let screenWidth = oHtml.clientWidth
    let screenHeight = oHtml.clientHeight
    if (screenHeight / screenWidth < 1.6) {
      screenWidth = screenHeight / 1.6
    }

    oHtml.style.fontSize = `${screenWidth / (750 / 40)}px`
  }
  render() {
    return (
      <div>
        {this.props.children}
      </div>
    )
  }
}


