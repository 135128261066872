import React, { Component } from 'react'
import { connect } from 'react-redux'
import { history } from '../../routers' 
import { Layout } from 'antd'
import ScanModal from './scanModal'
import CommonModal from '../../components/CommonModal/commonModal'
import MyIcon from '../../components/myIcon'
import { Avatar, Row, Col, Tooltip  } from 'antd'
import './layouts.styl'

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
    }
  }
  componentDidMount() {
    this.props.setBreadPosition(localStorage.breadPosition)
    if(window.location.pathname !== '/login' && window.location.pathname !== '/h5/h5login') {
      this.props.getCurrentUser()
    }
  }
    
  goLogin = () => {
    if (!this.state.image) {
      history.push('/login')
    }
  }
  logout = () => {
    this.CommonModal.show({
      title: '退出登录',
      msg: '确认退出登录吗',
      modalType: 'confirm',
      okFunc: this.okFunc,
    })
  }
  okFunc = () => {
    this.props.logout()
    history.push('/login')
  }
  render() {
    const { Header } = Layout;
    const { authentication, realName, pic } = this.props.personal

    const text = 
    <div className='toolTipAction' style={{ width: '116px', height: '134px', fontSize: '14px', color: '#333333', fontWeight: 400, textAlign: 'center' }}>
      <p onClick={() => {history.push('/personal/info')}}>个人信息</p>
      <p onClick={() => {history.push('/personal/restPwd')}}>修改密码</p>
      <p onClick={() => {history.push('/personal/realName')}}>关于我们</p>
      <p onClick={this.logout}>退出登录</p>
    </div>
    return (
      <div className='header'>
        <CommonModal onRef={(e) => {this.CommonModal = e}} />

        <Header theme='dark' style={{ background: '#fff', padding: '0 0px', height: 64, boxShadow: '0px 0px 6px 0px rgba(219,222,229,1)' }} >
          <Row type='flex' justify='space-between' align='middle'>
            <Col style={{paddingLeft: 20}}>
              {
                this.props.showLogo ? <img src={require('../../assets/images/logo-g.png')} alt=""/> : null
              }
              
            </Col>
            <Col style={{paddingRight: 52}}>
              <Tooltip placement="bottomRight" title={text}>
                <Row type='flex' justify='space-between' align='center'>
                  <div style={{marginRight: 10, position: 'relative', display: 'flex', alignItems: 'center'}} className='modal'>
                    <span style={{marginRight: 10}}>{realName}</span>
                    <MyIcon type='icontouxiang' style={{fontSize: 20, background: '#F6F6F9', borderRadius: '50%', padding: '6px'}} />
                    {/* <Avatar
                      className='cursor'
                      size={36}
                      src={ pic || require('../../assets/images/pohto_b.png')}
                      onClick={() => {this.setState({visible: true})}}/> */}
                  </div>
                  
                </Row>
              </Tooltip>
            </Col>
          </Row> 
        </Header>
      </div>
    )
  }
}

const mapState = state => {
  return {
    state: state.Layouts,
    personal: state.Personal
  }
}

const mapDispatch = (dispatch) => {
  const { Layouts, Personal } = dispatch
  const { setBreadPosition, logout } = Layouts
  const { getCurrentUser } = Personal
  return {
    setBreadPosition: (pos) => setBreadPosition(pos),
    getCurrentUser: (pos) => getCurrentUser(pos),
    logout: () => logout()
  }
}

export default connect(mapState, mapDispatch)(Header);