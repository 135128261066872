import request from '../../request'
// import { baseUrl } from '../../request'
import { modelMsg } from '../../components/modelMethods'
import { push } from 'react-router-redux'
const md5 = require('js-md5')
function arraryContact(code, text) {
  let arr = []
  if(code !== '') {
    code.split(',').forEach((v,i) => {
      arr.push({code: v, text: text.split(',')[i]})
    })
  }
  return arr
}

export default {
  state: {
    name: 'Personal',
    userInfo: { permsGroupId: '', idCardFront: '', doctorLicense: '', doctorTitleCert: '', idCardBack: '', idCard: '', titleText: '', relatedHospitalText: '', title: '', nationality: '', delFlag: '', department: '', phone: '', selfIntroduction: '', skills: '', userId: '', userStatus: '' },
    headPic: '',
    realName: '',
    authentication: false,
    loading: true,
    nation: [],//国家
    tit: [],//职称
    dePart: [],//科室
    hospitalCode: '',
    status: -1,
    msg: '',
    pic: '',
    relatedHospitals: [],
    oldPasswordErr: false
  },
  reducers: {
    updateState(state, payload) {
      return { ...state, ...payload }
    },
    showModal(state) {
      return { ...state, ...{ visible: true, confirmLoading: false } }
    },
    closeModal(state) {
      return { ...state, ...{ visible: false, confirmLoading: false } }
    },
    showConfirmLoading(state) {
      return {
        ...state,
        ...{ confirmLoading: true }
      }
    },
    closeConfirmLoading(state) {
      return {
        ...state,
        ...{ confirmLoading: false }
      }
    },
    closeAlert(state) {
      return { ...state, ...{status: -1} }
    }
  },
  effects: (dispatch) => ({
    async getCurrentUser() {
      const url = '/api/user/getCurrentUserInfo'
      const res = await request.get(url)
      if(res.status === 0) {
        this.updateState({
          userInfo: res.data,
          realName: res.data.realName,
          authentication: res.data.authentication,
          hospitalCode: res.data.hospitalCode,
        })
      }
    },
    async getArea(payload) {
      dispatch.UserControll.getArea(payload)
    },
    async getHospitals(payload) {
      dispatch.UserControll.getHospitals(payload)
    },
    // 获取国籍和职称
    async getByDictKey(payload) {
      const url = '/hospital/api/sys/dict/getByDictGroup'
      const params = payload
      const res = await request.get(url, { params })
      if(res.status === 0) {
        if(params.dictGroup === 'title') {
          this.updateState({
            tit: res.data
          })
        } else {
          this.updateState({
            nation: res.data
          })
        }
      }
    },
    // 获取科室
    async getDepart(payload) {
      const url = '/hospital/api/sys/hospital/departments'
      const params = payload
      const res = await request.get(url, { params })
      if (res.status === 0) {
        this.updateState({
          dePart: res.data
        })
      }
    },
    async editUser(payload) {
      const url = '/auth/api/sys/user/editUserSkillInfo'
      const params = payload
      const res = await request.post(url, params )
      if (res.status === 0) {
        // modelMsg(res.status, '修改成功')
        this.updateState({
          status: res.status,
          msg: '修改成功'
        })
        this.getCurrentUser()
      } else {
        // modelMsg(res.status, res.msg)
        this.updateState({
          status: res.status,
          msg: res.msg
        })
        // this.getCurrentUser()
      }
    },
    async certification(payload) {
      const url = '/auth/api/sys/user/certification'
      const params = { ...payload, ...{ userStatus: 0} }
      const res = await request.post(url, params )
      if (res.status === 0) {
        // modelMsg(res.status, '修改成功')
        this.updateState({
          status: res.status,
          msg: '修改成功'
        })
        this.getCurrentUser()
      } else {
        // modelMsg(res.status, res.msg)
        this.updateState({
          status: res.status,
          msg: res.msg
        })
        // this.getCurrentUser()
      }
    },
    async modifyPwd(payload) {
      this.updateState({
        oldPasswordErr: false
      })
      const url = '/api/user/changePassword'

      const params = {
        oldPassword: md5(payload.oldPassword  + 'truthai^&%$'),
        newPassword: md5(payload.password  + 'truthai^&%$'),
        confirmPassword: md5(payload.newPassword  + 'truthai^&%$')
      }

      const res = await request.post(url, params)
      if (res.status === 0) {

        modelMsg(res.status, '修改成功,请重新登录')
        localStorage.clear()
        document.cookie = 'ps=;path=/'
        dispatch(push('/login'))
      } else {
        // modelMsg(res.status, res.msg)
        this.updateState({
          oldPasswordErr: true
        })
        // modelMsg(res.status, res.msg)
      }
    }
  })
}