export default {
  state: {
    name: 'Index'
  },
  reducers: {
    updateState(state, { payload }) {
      return { ...state, ...payload }
    }
  },
  effects: {

  }
}