import React from 'react'
import ReactDOM from 'react-dom'

import axios from 'axios'
import { push } from 'react-router-redux'
import { dispatch } from './store'
import { modelMsg } from './components/modelMethods'
import CommonModal from './components/CommonModal/commonModal'


const root = document.getElementById('root')
let CommonModalMethod

let div = document.createElement('div')
// div.id = 'xxxe'
setTimeout(() => {
  root.appendChild(div)
  ReactDOM.render(
    <CommonModal onRef={e => CommonModalMethod = e}/>,
    div
  )
})


export let baseUrl

const url = window.location.host

if (process.env.NODE_ENV === 'development') {
  // dev 开发环境下 固定此 baseUrl
  baseUrl = 'http://cloud-ecg.local.truthai.cn/ecg-api'
  // baseUrl = 'http://realeye.truthai.cn/re-api'
  // baseUrl = 'http://192.168.0.217:8088/ecg-api'

} else {
  // pro 环境下，需要判断, 如果是local环境则用local api
  // if (url.startsWith('local')) {
  //   baseUrl = 'http://cloud-ecg.local.truthai.cn/ecg-api'
  // } else {
  //   baseUrl = 'http://192.168.0.217:8088'
  // }

  baseUrl = '/ecg-api'

}

const request = axios.create({
  baseURL: baseUrl,
  timeout: 30000,
  transformRequest: [function (data) {
    let ret = ''
    for (let it in data) {
      ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
    }
    return ret
  }],
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  }

})

window.readImgWithoutToken = function(imgSno) {
  if (!imgSno) {
    return ''
  }
  return `${baseUrl}/api/image/viewImage/${imgSno}`
}

window.readImgDiy600 = function(imgSno) {
  if (!imgSno) {
    return ''
  }
  return `${baseUrl}/image/api/sys/image/viewThumbnail/${imgSno}?Authorization=Bearer%20${localStorage.token}&width=600&height=600`
}

window.readImgWithStudyBizNo = function(imgSno, serialNumber) {
  if (!imgSno) {
    return ''
  } 
  return `${baseUrl}/image/api/sys/image/viewReportThumbnail/${imgSno}?serialNumber=${serialNumber}`
}

window.readImg = function(imgSno, needsHold = true) {
  if (imgSno && imgSno !== 'undefined') {
    return `${baseUrl}/image/api/sys/image/viewImage/${imgSno}?Authorization=Bearer%20${localStorage.token}`
  }
  if (needsHold) {
    return require('./assets/images/noImg.png')
  }
  return ''
}
window.captchaImage = function(captchaKey) {
  if (captchaKey && captchaKey !== 'undefined' ) {
    return `${baseUrl}/auth/api/sys/user/getCaptchaImage?captchaKey=${captchaKey}`
  }

  return require('./assets/images/noImg.png')
}


let sameMsg

function checkStatus (error) {
  const res = error.response
  if (res) {
    // 若option请求失效，则会出现后续接口跨域问题，从而取不到response
    if (res.status == 403) {
      modelMsg(res.status, '登录信息校验错误，请重新登录')
      dispatch(push('/login'))
      error.notified = true
    }

    if (res.status === 500) {
      modelMsg(res.status, res.msg || res.data.msg || res.statusText || '操作异常')
      dispatch(push('/login'))
      error.notified = true
    } else if (!error.notified && sameMsg !== res.statusText && res.config.method !== 'get' ) {
      // 同一种错误信息 只提示一次
      sameMsg = res.statusText || ''
      modelMsg(res.status, sameMsg)
    } else if (res.status === 401) {
      modelMsg(res.status, '登录已失效')
      if (window.screen.width <= 500) {
        if (window.location.href.indexOf('active1') > -1) {
          dispatch(push('/h5/active1/login'))
        } else {
          dispatch(push('/h5/h5login'))
        }
      } else {
        dispatch(push('/login'))
      }
    }
    error.notified = true
  } else {
    modelMsg(1, error.message)
    // dispatch(push('/login'))
  }
  return Promise.reject(error)
}

function doNothingNow (res) {
  if (res.status == 200) {
    if (res.data.status === 3) {
      CommonModalMethod.show({
        title: '账号停用',
        msg: '您的账号已被管理员停用，如有问题请联系管理员！',
        titleType: 'danger',
      })
    }
  }
  
  if (res.headers['x-token-expired']) {
    modelMsg(3, '登录信息校验错误，请重新登录')
    if (window.screen.width <= 500) {
      if (window.location.href.indexOf('active1') > -1) {
        dispatch(push('/h5/active1/login'))
      } else {
        dispatch(push('/h5/h5login'))
      }
    } else {
      dispatch(push('/login'))
    }
  }
  return res.data
}

function isTokenExpired() {
  // 小token是否过期
  const TEI = parseInt(localStorage.TEI)
  const ST = parseInt(localStorage.ST)
  const time = new Date().valueOf()

  if (time - ST > (TEI - 10) * 1000 || !localStorage.token ) {
    return true
  }
  return false
}

function isRefreshExpired() {
  // 大token是否过期
  const RTEI = parseInt(localStorage.RTEI)
  const ST = parseInt(localStorage.ST)
  const time = new Date().valueOf()

  if (time - ST >  RTEI * 1000 || !localStorage.refreshToken) {
    return true
  }

  return false
}


window.isRefreshing = false
/*被挂起的请求数组*/
let refreshSubscribers = []

/*push所有请求到数组中*/
function subscribeTokenRefresh (cb) {
  refreshSubscribers.push(cb)
}

/*刷新请求（refreshSubscribers数组中的请求得到新的token之后会自执行，用新的token去请求数据）*/
function onRrefreshed(token) {
  refreshSubscribers.map(cb => cb(token))
  refreshSubscribers = []
}

function cannotRefresh(apiUrl) {
  if (apiUrl) {
    const isRefreshTokenApi = !apiUrl.endsWith('refreshToken')
    const isLoginApi = !apiUrl.endsWith('login')
    const isLoginByCodeApi = !(apiUrl.indexOf('loginByCode') > -1)
    const isUserReportCheck = !(apiUrl.indexOf('userReportCheck') > -1)
    const isResetPwdApi = !(apiUrl.indexOf('resetPwd') > -1)
    const isRegisterApi = !(apiUrl.indexOf('register') > -1)
    const isGetUserReportDetail = !(apiUrl.indexOf('getUserReportDetail') > -1)
    const isGetDictByKey = !(apiUrl.indexOf('getDictByKey') > -1)

    const isGetImageWithoutToken = !(apiUrl.indexOf('viewCommonImage') > -1)
    const isGetReportEle = !(apiUrl.indexOf('getReportElement') > -1)
    if (isGetReportEle && isGetImageWithoutToken && isGetDictByKey && isGetUserReportDetail && isUserReportCheck && isRefreshTokenApi && isLoginApi && isLoginByCodeApi && isResetPwdApi && isRegisterApi) {
      return true
    }
  }

  return false

}

async function refresh (config) {
  const _config = config

  const apiUrl = _config.url
  const token = localStorage.token || 'no_token'
  _config.headers['Authorization'] = 'Bearer ' + token
  _config.headers['token'] =  token


  if (cannotRefresh(apiUrl)) {

    if (isTokenExpired() && !isRefreshExpired()) {
      if (!window.isRefreshing) {

        window.isRefreshing = true
        var newToken = await dispatch.Login.refreshToken()
        _config.headers['Authorization'] = 'Bearer ' + newToken
        _config.headers['token'] =  token
        setTimeout(() => {
          onRrefreshed(newToken)
        })
      }

      let retry = new Promise((resolve) => {
        subscribeTokenRefresh((authToken) => {
          _config.headers['Authorization'] = 'Bearer ' + authToken
          _config.headers['token'] =  token
          /*将请求挂起*/
          resolve(_config)
        }) 
      })

      return retry
    }
    return _config  
  }
  return _config
}

request.interceptors.request.use(refresh, error => Promise.reject(error))
request.interceptors.response.use(doNothingNow, checkStatus)
export default request