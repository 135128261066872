import request from '../../request'
import { modelMsg } from '../../components/modelMethods'
import { push } from 'react-router-redux'

export default {
  state: {
    name: 'RegisterList',
    params: {
      page: 1,
      pageSize: 10,
      reportState: '',
      reportDoctorUserId: '',
      searchStr: ''
    },
    waveData: [],
    reportInfo: {},
    registerDetailInfo: {},
    registerList: [],
    hospitalList: [],
    reportDoctorList: [],
    stateList: [
      { label: '全部', value: '' },
      { label: '未上传', value: '1' },
      { label: '已上传', value: '2' },
      { label: '已报告', value: '3' }
    ],
    resultList: [
      { label: '-', value: '0' },
      { label: '正常', value: '1' },
      { label: '再检查', value: '2' },
      { label: '疑似病例', value: '3' }
    ],
    genderList: [
      { label: '全部', value: '-1' },
      { label: '男', value: '1' },
      { label: '女', value: '2' },
      { label: '未知', value: '3' }
    ],
    sourceTypeList: [
      { label: '全部', value: -1 },
      { label: '导入', value: 1 },
      { label: '录入', value: 3 }
    ],
    hospitalCode: '',
    totalRows: 1,
    expirationTime: 0,
    dynamicCode: '',
    serialNumber: '',
    highPassFreq: '',
    lowPassFreq: '',
    notchFreq: '',
    allData: {}
  },
  reducers: {
    updateState(state, payload) {
      return { ...state, ...payload }
    }
  },
  effects: dispatch => ({
    // async outPut() {
    //   const url = '/patient/api/screening/exportList'
    //   const params = payload
    //   cons
    // },
    async getArea(payload) {
      dispatch.UserControll.getArea(payload)
    },
    async getHospitals(payload) {
      dispatch.UserControll.getHospitals(payload)
    },

    async getReportDocotr(payload) {
      const url = `/api/user/doctorUsers`
      const res = await request.get(url)
      if (res.status === 0) {
        this.updateState({
          reportDoctorList: res.data
        })
      }
    },
    async getEcgData(payload) {
      const url = `/api/study/getAllEcgCoordinates/${payload.id}`
      let res = await request.get(url)
      if (res.status === 0) {
        this.updateState({
          allData: res.data
        })
      }
    },
    async getEcgCoordinates(payload) {
      const url = `/api/study/ecgCoordinates/${payload.id}`
      const res = await request.get(url)

      if (res.status === 0) {
        console.log(res)
        const waveData = []
        const data = res.data
        for (let i of data) {
          // const list = []
          // for (let j of i.coordinates) {
          //   list.push([j.x, j.y])
          // }
          // waveData.push(list)
          waveData.push(i.coordinates)
        }
        console.log(waveData)
        this.updateState({
          waveData
        })
      }
    },

    async getRegisterList(payload = {}, rootState) {
      this.getReportDocotr()
      const url = '/api/study/page'
      const params = { ...rootState.RegisterList.params, ...payload }
      const res = await request.get(url, { params })

      if (res.status === 0) {
        this.updateState({
          registerList: res.data.pageData,
          totalRows: res.data.totalRows,
          params
        })
      }
    },

    async addRegister(payload) {
      const url = '/api/study/addStudy'
      const params = payload

      const res = await request.post(url, params)
      if (res.status === 0) {
        setTimeout(() => {
          dispatch(push('/registerList'))
        })
        modelMsg(res.status, '新增检查成功！')
      } else {
        modelMsg(res.status, res.msg)
      }
    },
    async getRegisterDetail(payload) {
      this.getEcgData(payload)
      const url = `/api/study/getStudy/${payload.id}`
      const res = await request.get(url)
      if (res.status === 0) {
        if (res.data) {
          res.data.report = res.data.report ? res.data.report : {}
          res.data.report.aiResult = res.data.aiResult
        }
        this.updateState({
          registerDetailInfo: res.data,
          reportInfo: res.data.report
        })
      } else {
        modelMsg(res.status, res.msg)
      }
    },

    async deleteRegister(payload) {
      const url = `/api/study/delStudy/${payload.id}`
      const res = await request.delete(url)
      if (res.status === 0) {
        this.getRegisterList()
      }
      modelMsg(res.status, res.msg)
    },

    async submit(payload) {
      const url = '/api/study/fillReport'
      const params = payload
      const res = await request.post(url, params)
      if (res.status === 0) {
        modelMsg(res.status, '报告已完成')
        this.getRegisterDetail({
          id: payload.studyId
        })
      } else {
        this.getRegisterDetail({
          id: payload.studyId
        })
        modelMsg(res.status, res.msg)
      }
    },
    async submitNext(payload) {
      const url = '/api/study/fillReportAndNext'
      const params = payload
      const res = await request.post(url, params)
      if (res.status === 0) {
        if (res.data) {
          modelMsg(res.status, '报告已完成！自动跳转下一例中…')
          const id = res.data.id || res.data.studyId
          window.location.href = `/registerDetail/${id}`
        } else {
          modelMsg(res.status, '检查均已完成！')
          this.getRegisterDetail({
            id: payload.studyId
          })
        }
      } else {
        modelMsg(res.status, res.msg)
      }
    }
  })
}
