import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Breadcrumb } from 'antd'
import { history } from '../../routers'


class Bread extends Component {
  componentDidMount() {
    this.props.setBreadPosition(localStorage.breadPosition)
  }
  goIndex = () => {
    history.push('/')
  }
  render() {
    return (
      <Breadcrumb separator=">" style={{ padding: '10px 0', paddingLeft: 20, marginBottom: 0 }}>
        <Breadcrumb.Item>{this.props.state.indexPosition || localStorage.indexPosition}</Breadcrumb.Item>
        <Breadcrumb.Item>{this.props.state.breadPosition || localStorage.breadPosition}</Breadcrumb.Item>
        <Breadcrumb.Item>{this.props.state.seBreadPosition}</Breadcrumb.Item>
      </Breadcrumb>
    )
  }
}

const mapState = state => {
  return {
    state: state.Layouts
  }
}

const mapDispatch = (dispatch) => {
  const { Personal, Layouts } = dispatch
  const { getCurrentUser } = Personal
  const { setBreadPosition } = Layouts
  return {
    setBreadPosition: (pos) => setBreadPosition(pos),
    getCurrentUser: () => getCurrentUser()
  }
}

export default connect(mapState, mapDispatch)(Bread);