import React from 'react'
import { message } from 'antd' 
import MyIcon from './myIcon'

export const modelMsg = (status, msg) => {
  let icon
  if(status === 0) {
    icon = <MyIcon type='iconchenggong' /> 
  } else if (status === 1) {
    icon = <MyIcon type='icontishi' />
  } else {
    icon = <MyIcon type='iconjinggao' />
  }
  const node = <span style={{color: 'rgba(0,0,0,0.85)'}}> {msg} </ span>
  message.open({icon, content: node})
}