import request from '../../request'
import axios from 'axios'
import { baseUrl } from '../../request'
import { push } from 'react-router-redux'
import { modelMsg } from '../../components/modelMethods'

export default {
  state: {
    name: 'ActiveSerial',
    contetErr: false
  }, // initial state
  reducers: {
    updateState(state, payload) {
      return { ...state, ...payload }
      // return Object.assign({}, state, payload)
    },
    closeAlert(state) {
      return { ...state, ...{ status: -1 } }
    }
  },
  effects: (dispatch) => ({
    async activeUser(payload) {
      const cb = payload.cb
      this.updateState({
        contetErr: false
      })
      const { content, organizationId } = payload
      const url = `/api/serialNumber/activeOrganization/${content}/${organizationId}`
      const res = await request.post(url)

      if (res.status === 0) {
        this.updateState({
          contetErr: false
        })
        // localStorage.token = res.data
        cb({
          title: '序列号正确',
          msg: '验证通过，可正常使用本系统！',
          titleType: 'success',
          cancelMsg: '开始使用',
          cancelFunc: function() {
            dispatch(push('/'))

          }
        })
      } else {
        this.updateState({
          contetErr: true
        })
      }
    }
  })
}