import React, { Component } from 'react'
import Routers from '../../routers'

import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

class App extends Component {
  render() {
    return (
      <Routers />
    )
  }
}

const mapState = state => {
  return {
    app: state.App
  }
}

const mapDispatch = ({ App: { appAdd, appAddAsync } }) => ({
  appAdd: () => appAdd(2),
  appAddAsync: () => appAddAsync(1)
})

export default withRouter(connect(mapState, mapDispatch)(App))
