import App from './views/App/model'
import Layouts from './views/Layouts/model'
import Login from './views/Login/model'
import ActiveSerial from './views/ActiveSerial/model'


import Permission from './views/Permission/model'
import UserControll from './views/UserControll/model'
import RoleControll from './views/RoleControll/model'
import HospitalControll from './views/HospitalControll/model'

import Index from './views/Index/model'
import DataDisplay from './views/DataDisplay/model'
import Personal from './views/Personal/model'

import EcgUserList from './views/EcgUserList/model'
import RegisterList from './views/RegisterList/model'

import EcgHospitalList from './views/EcgHospitalList/model'
import EcgView from './views/EcgView/model'


// 健康筛查 -------------------------------------



/* 活动模块 active */
const models = {
  Index,
  App,
  Personal,
  Layouts,
  Permission,
  UserControll,
  Login,
  ActiveSerial,
  RoleControll,
  HospitalControll,
  DataDisplay,
  EcgUserList,
  RegisterList,
  EcgHospitalList,
  EcgView
}

export default models
